import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Advice.css';
import Lenis from "@studio-freight/lenis";
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Advice = () => {
  const [advice, setAdvice] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchStatus, setFetchStatus] = useState('');
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const fetchClickCount = async () => {
      try {
        const response = await fetch('/.netlify/functions/firebase', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'getClickCount' }),
        });

        if (response.ok) {
          const data = await response.json();
          setClickCount(data.clickCount || 0);
        } else {
          console.error('Error fetching click count:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching click count:', error);
      }
    };

    fetchClickCount();
  }, []);


  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smooth: true,
      smoothTouch: true,
      smoothWheel: true,
    });

    const onScroll = (time) => {
      lenis.raf(time);
      requestAnimationFrame(onScroll);
    };

    requestAnimationFrame(onScroll);

    return () => {
      lenis.destroy();
    };
  }, []);

  const fetchAdvice = async () => {
    const currentTime = new Date().getTime();
  
    if (currentTime - lastFetchTime < 5000) {
      toast.warn('You can request an Advice again in 5 seconds!', {
        className: 'custom-toast custom-toast-warning',
      });
      return;
    }
  
    setLoading(true);
    setFetchStatus('');
    setAdvice('');
  
    try {
      const response = await fetch('/.netlify/functions/fetchAdvice');
      const data = await response.json();
  
      if (data.advice) {
        setAdvice(data.advice);
        setFetchStatus('success');
  
        toast.success('Advice Given!', {
          className: 'custom-toast custom-toast-success',
        });
  
        setTimeout(() => {
          setFetchStatus('');
        }, 1500);
  
        // Increment click
        const incrementResponse = await fetch('/.netlify/functions/firebase', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'incrementClickCount' }),
        });
  
        if (incrementResponse.ok) {
          const updatedCountResponse = await fetch('/.netlify/functions/firebase', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ action: 'getClickCount' }),
          });
  
          if (updatedCountResponse.ok) {
            const updatedData = await updatedCountResponse.json();
            setClickCount(updatedData.clickCount || 0);
          }
        }
      } else {
        setAdvice('No advice available at the moment.');
        setFetchStatus('error');
      }
  
      setLastFetchTime(currentTime);
    } catch (error) {
      setAdvice('Error fetching advice. Please try again later.');
      setFetchStatus('error');
    } finally {
      setLoading(false);
    }
  };
  

  let buttonText = 'Get Random Advice';
  if (loading) {
    buttonText = 'An Advice is Cooking...';
  } else if (fetchStatus === 'success') {
    buttonText = 'Success✨';
  } else if (fetchStatus === 'error') {
    buttonText = 'Try Again';
  }

  return (
    <div className="advice-section">
      <Helmet>
        <title>Nisal Herath - Advice-Wizard </title>
        <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
        <meta name="keywords" content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka" />
        <meta name="author" content="Nisal Herath" />
        <meta property="og:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta property="og:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta property="og:image" content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364" />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta name="twitter:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="advice-main">
        <div className="advice">
          <Link to="/#fun" className="backhome3" style={{ textDecoration: "none", color: "inherit" }}>
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back Home
          </Link>

          <motion.div
            className="advice-grid"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.6 }}
          >
            <div className="advice-window">
              <div className={`wizzard ${loading ? 'wizzanimating' : ''}`}><h1>🧙🏻‍♂️</h1></div>
              <p className={`advice-text ${loading ? 'adviceanimating' : ''}`}>
                {loading ? 'Wizard is Thinking...🪄' : advice || 'Click 👇 to Get an Advice'}
              </p>
            </div>

            <div className="advice-btn">
              <button
                onClick={fetchAdvice}
                className='advice-button'>
                {buttonText}
              </button>
            </div>

            <div className="poweredadvice">
              <p>
                Powered by{' '}
                <a href="https://api.adviceslip.com/" target="_blank" rel="noopener noreferrer">
                  Adviceslip
                </a>
              </p>
            </div>
          </motion.div>

          <div className="click-count">
            <div className="totaladvice">Wizard has Given a total of <span className="click-count">{clickCount}</span> advices to the Peasants.</div>
          </div>

          <ToastContainer className="custom-toast-container" />
        </div>
      </div>
    </div>
  );
};

export default Advice;
