import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import HomePage from './Components/HomePage/HomePage';
import NotFound from './Components/NotFound/Notfound';
import CSStips from './Components/CSStips/CSStips';
import Nerdcake from './Components/Nerdcake/Nerdcake';
import Advice from './Components/Advice/Advice';
import Astro from './Components/Astro/Astro';
import QrGen from './Components/QrGen/QrGen';
import WallGallery from './Components/WallGallery/WallGallery';
import './App.css';

const validSections = ['about', 'experience', 'skills', 'projects', 'fun'];

const useScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.substring(1).toLowerCase();

    if (sectionId && validSections.includes(sectionId)) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (sectionId) {
      window.location.href = '/404';
    }
  }, [location]);
};

function App() {
  return (
    <Router>
      <ScrollHandler />
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/css" element={<CSStips />} />
            <Route path="/nerdcake" element={<Nerdcake />} />
            <Route path="/advice" element={<Advice />} />
            <Route path="/astro" element={<Astro />} />
            <Route path="/qr-generator" element={<QrGen />} />
            <Route path="/wallpaper-gallery" element={<WallGallery />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </motion.div>
      </AnimatePresence>
    </Router>
  );
}

function ScrollHandler() {
  const location = useLocation();

  // Ensure content is positioned at the top of the page on route change
  useEffect(() => {
    // Use window.scrollTo to set scroll position to the top of the page
    window.scrollTo(0, 0);
  }, [location]);

  useScrollToSection();

  return null;
}

export default App;
