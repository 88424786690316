import React, { useState } from 'react';
import '../Footer/Footer.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import facebookLogo from '../Assets/fb.svg';
import instagramLogo from '../Assets/insta.svg';
import twitterLogo from '../Assets/x.svg';
import behanceLogo from '../Assets/behance.svg';
import githubLogo from '../Assets/git.svg';
import codepenLogo from '../Assets/codepen.svg';
import zedgeLogo from '../Assets/zedge.svg';
import discordLogo from '../Assets/discordlogo.svg';
import steamLogo from '../Assets/steam.svg';
import linkedinimg from '../Assets/linkedin.svg';
import { Helmet } from 'react-helmet';

function Footer() {
  const [formData, setFormData] = useState({
    email: '',
    reason: '',
    honeypot: '',
  });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const currentYear = new Date().getFullYear();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.honeypot) {
      console.log('Bot detected. Form submission blocked.');
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await fetch('/.netlify/functions/emailHandler', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from_email: formData.email,
          reason: formData.reason,
          to_email: formData.email,
        }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setAlertMessage('Thank you for contacting me! Check your email for confirmation.');
        setFormData({ email: '', reason: '', honeypot: '' });
      } else {
        throw new Error(result.message || 'Failed to send email');
      }
    } catch (error) {
      setAlertMessage('Error submitting the form. Please try again later.');
    } finally {
      setShowAlert(true);
      setLoading(false);
    }
  };
  



  const socialMedia = {
    facebook: { alt: 'Facebook', url: 'https://web.facebook.com/mooncaque', logo: facebookLogo },
    instagram: { alt: 'Instagram', url: 'https://www.instagram.com/mooncaque/', logo: instagramLogo },
    twitter: { alt: 'Twitter', url: 'https://x.com/ArtNisal', logo: twitterLogo },
    behance: { alt: 'Behance', url: 'https://www.behance.net/nisalherath', logo: behanceLogo },
    github: { alt: 'GitHub', url: 'https://github.com/nisalherath', logo: githubLogo },
    codepen: { alt: 'Codepen', url: 'https://codepen.io/Mooncaque', logo: codepenLogo },
    zedge: { alt: 'Zedge', url: 'https://marketplace-next.prod.zedge.net/profile/2691d015-a81a-4494-aa52-f80b5fc164ba', logo: zedgeLogo },
    discord: { alt: 'Discord', url: 'https://discord.com/users/790576125678977065', logo: discordLogo },
    steam: { alt: 'Steam', url: 'https://steamcommunity.com/profiles/76561199120331976/', logo: steamLogo },
    linkedin: { alt: 'Linkedin', url: 'https://www.linkedin.com/in/nisal-herath/', logo: linkedinimg },
  };

  return (
    <div className="footer-main">
<Helmet>
        <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
        <meta name="keywords" content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka" />
        <meta name="author" content="Nisal Herath" />
        <meta property="og:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta property="og:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta property="og:image" content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364" />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta name="twitter:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" /> 
        <meta name="robots" content="index, follow" />

  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": "Nisal Herath",
        "url": "https://nisal.lk",
        "sameAs": [
          "https://www.linkedin.com/in/nisalherath",
          "https://github.com/nisalherath"
          "https://www.instagram.com/mooncaque/",
          "https://x.com/ArtNisal"
        ],
        "jobTitle": "Web Developer & Automation Specialist",
        "worksFor": {
          "@type": "Organization",
          "name": "SLIIT"
        },
        "image": "https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364",
        "description": "Nisal Herath is a web developer specializing in React and automation solutions for streamlining tasks."
      }
    `}
  </script>
</Helmet>
      <h1 className="contactme">Contact Me</h1>
      <p className="rights">📨 email : nisal@nisal.lk</p>
      <p className="rights">or</p>
      <footer className="footer">
        <form onSubmit={handleSubmit} className="contact-form" autoComplete="on">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your email"
              required
              autoComplete="email" 
            />
          </div>
          <div className="form-group">
            <label htmlFor="reason">Reason for Contact</label>
            <input
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              placeholder="What For?"
              required
              autoComplete="off"
            />
          </div>
          <div className="hidden-field">
            <input
              type="text"
              name="honeypot"
              value={formData.honeypot}
              onChange={handleChange}
              placeholder="Leave this field blank"
              autoComplete="off"
            />
          </div>
          <button type="submit" className="formbtn" disabled={loading}>
            {loading ? '⌛' : 'Submit'}
          </button>
        </form>

        <div className="social-buttons">
  {Object.keys(socialMedia).map((key) => (
    <a
      key={key}
      href={socialMedia[key].url}
      target="_blank"
      rel="noopener noreferrer"
      className={`social-button ${key}`}
      aria-label={`Visit ${socialMedia[key].alt} profile`}
    >
      <img
        src={socialMedia[key].logo}
        alt={socialMedia[key].alt}
        data-tooltip-id="custom-tooltip"
        data-tooltip-content={socialMedia[key].alt}
      />
    </a>
  ))}
</div>

        <ReactTooltip
          id="custom-tooltip"
          effect="solid"
          place="top"
          multiline={true}
          className="custom-tooltip"
          clickable={true}
          delayShow={100}
          delayHide={100}
          positionStrategy="fixed"
          resizeObserver={true}
          closeOnScroll={true}
          arrowColor="rgba(31, 22, 83, 0.7)"
        />
        {showAlert && (
          <div className="alert-backdrop">
            <div className="alert">
              <p>{alertMessage}</p>
              <button onClick={() => setShowAlert(false)}>OK</button>
            </div>
          </div>
        )}
        <div className="reservedp">
        <p className="rights">© {currentYear} All Rights Reserved</p>
          <p className="rights">nisal.lk</p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
