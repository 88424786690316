import React, { useEffect, useState, useCallback } from "react";
import "./WallGallery.css";
import Lenis from "@studio-freight/lenis";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import UnsplashLogo from '../Assets/unsplash.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FiDownload } from "react-icons/fi";

const WallGallery = () => {
  const [images, setImages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => 1 - Math.pow(1 - t, 4),
      smooth: true,
      smoothTouch: true,
      smoothWheel: true,
    });

    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };
    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  const fetchImages = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await fetch(
        `/.netlify/functions/wallpaperFetch?pageNumber=${pageNumber}`
      );
  
      if (!response.ok) {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (!Array.isArray(data)) {
        throw new Error("Unexpected data format received from Unsplash API");
      }
  
      setImages((prevImages) => {
        const existingImages = new Set(prevImages.map((img) => img.src));
        const newImages = data.filter(
          (newImage) => !existingImages.has(newImage.src)
        );
        return [...prevImages, ...newImages];
      });
    } catch (error) {
      console.error("Error fetching images from Unsplash:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      !loading
    ) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [loading]);

  useEffect(() => {
    fetchImages(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);


  const handleDownload = (src) => {
    const newTab = window.open(
      `${src}?utm_source=NerdCake&utm_medium=referral`,
      "_blank",
      "noopener noreferrer"
    );
    if (newTab) newTab.opener = null;
  };
  
  return (
    <div className="Wall-gallery">
      <div className="titlediv2">
        <div className="Wally-title">


          <Link to="/#fun" className="backhome3" style={{ textDecoration: "none", color: "inherit" }}>
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back Home
          </Link>


          <div className="poweredbyunsplash">
            <p>Powered By Unsplash</p>
            <a href="https://unsplash.com" target="_blank" rel="noopener noreferrer" className="unsplash-link">
              <img src={UnsplashLogo} className="unsplashlogomain" alt="Unsplash Logo" />
            </a>
          </div>


          <motion.h1
            className="Wally-title2"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8 }}
          >
            Lets Doom-Scroll Images
          </motion.h1>


          <div className="unsplash-disclaimer">
            <p>
                All images displayed in this gallery are sourced from{" "}
              <a
               href="https://unsplash.com"
               target="_blank"
               rel="noopener noreferrer"
               className="unsplash-link"
              >
              Unsplash
              </a>, used under their license for free and commercial purposes with proper credit.  
              <br />
              Photographer credits are included with each image. For concerns, feel free to contact us.
            </p>
        </div>

        </div>
      </div>

      <div className="photo-gallery">
        {images.map((image, index) => (
          <div className="gallery-item">
            
              <a   
                href={image.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                aria-label={image.alt || "View full image on Unsplash"}
                >

                 <div className="image-container">
                 <LazyLoadImage
                    src={image.src}
                    alt={image.alt || "Wall Image"}
                    effect="blur"
                    wrapperClassName="gallery-item"
                    style={{
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "5px",
                            transition: "transform 0.3s ease",
                          }}
                  />
                <div className="image-items-unsplash">
                   <div className="author-name">{image.author ? `Photo by ${image.author}` : "Author Unknown"}</div>
                   <div
                      className="download-icon"
                      onClick={() => handleDownload(image.src)}
                     >
                     <FiDownload size={24} />
                    </div>
                </div>
                
                 </div>
              </a>
          </div>
        ))}
        {loading && <div className="loading-unsplash"></div>}
      </div>
    </div>
  );
};

export default WallGallery;
