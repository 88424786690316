import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar/Navbar';
import About from '../About/About';
import Experience from '../Experience/Experience';
import Skills from '../Skills/Skills';
import Projects from '../Projects/Projects';
import Footer from '../Footer/Footer';
import Fun from '../Fun/Fun';
import Lenis from '@studio-freight/lenis';
import './HomePage.css';

const HomePage = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const sectionsRef = useRef({});
  const lenisRef = useRef(null);

  // Initialize Lenis for smooth scrolling
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smooth: true,
      smoothTouch: true,
      smoothWheel: true,
    });
    lenisRef.current = lenis;

    function raf(time) {
      lenis.raf(time); // Ensure Lenis handles smooth scrolling
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  // Manage intersection observer for URL hash updates
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      sectionsRef.current[section.id] = section;
    });

    let lastUrlUpdate = Date.now();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const now = Date.now();
            if (now - lastUrlUpdate > 300) { // Throttle hash updates
              const sectionId = entry.target.id;
              window.history.pushState(null, '', `#${sectionId}`);
              lastUrlUpdate = now;
            }
          }
        });
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '0px 0px -20% 0px',
      }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  // Manage scroll-to-top button visibility
  useEffect(() => {
    const onScroll = () => {
      const bottomPosition =
        document.documentElement.scrollHeight -
        document.documentElement.scrollTop -
        window.innerHeight;
      setShowScrollToTop(bottomPosition < 50);
    };

    const handleScroll = () => requestAnimationFrame(onScroll);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle scroll-to-top action
  const scrollToTop = () => {
    lenisRef.current?.scrollTo(0, { immediate: false });
  };

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Nisal Herath - Home</title>
        <meta
          name="description"
          content="Web site created by Nisal Herath, showcasing projects and portfolio."
        />
        <meta
          name="keywords"
          content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka"
        />
        <meta name="author" content="Nisal Herath" />
        <meta
          property="og:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          property="og:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          property="og:image"
          content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364"
        />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          name="twitter:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          name="twitter:image"
          content="https://x.com/ArtNisal/photo"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Navbar />
      <main>

        <section id="about">
          <About />
        </section>
        <section id="experience">
          <Experience />
        </section>
        <section id="skills">
          <Skills />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="fun">
          <Fun />
        </section>
      </main>
      <Footer />
      {showScrollToTop && (
        <button
          className="scroll-to-top"
          onClick={scrollToTop}
          aria-label="Scroll to top of the page"
        >
          ⬆ Scroll to Top
        </button>
      )}
    </div>
  );
};

export default HomePage;
