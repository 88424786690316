import React, { useState,useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import "./Nerdcake.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Lenis from "@studio-freight/lenis";

import nerdcakeimg from '../Assets/nerdcake.webp';
import discord from '../Assets/discord.svg';
import codepenLogo from '../Assets/codepen.svg';
import discordLogo from '../Assets/discordlogo.svg';
import behanceLogo from '../Assets/behance.svg';
import githubLogo from '../Assets/git.svg';

const fadeVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const slideXVariant = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const slideInFromRight = {
  hidden: { x: "100%", opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const slideYVariant = {
  hidden: { y: -100, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};

const barHeightVariant = (height) => ({
  hidden: { height: "0%" },
  visible: { height },
});

const Nerdcake = () => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showReply, setShowReply] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [hovering, setHovering] = useState(false);


    // Lenis Initialization
    useEffect(() => {
      const lenis = new Lenis({
        duration: 1.2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // Smooth easing function
        smooth: true,
        smoothTouch: true,
        smoothWheel: true,
      });
  
      const onScroll = (time) => {
        lenis.raf(time);
        requestAnimationFrame(onScroll);
      };
  
      requestAnimationFrame(onScroll);
  
      return () => {
        lenis.destroy();
      };
    }, []);



  const handleMouseEnter = () => {
    setHovering(true);
    setPercentage(95);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  useEffect(() => {
    if (!hovering) {
      setPercentage(0);
    }
  }, [hovering]);

  const handleClick = () => {
    if (percentage === 0) {
      setPercentage(95);
    } else {
      setPercentage(0);
    }
  };


  const replyimg = 'https://res.cloudinary.com/dlnhogbjy/image/upload/v1734591096/Poooos_ndn8sd.webp';



  const messages = [
    { text: '/IP', reply: 'IP Address:📫10.21.16.25' },
    { text: '/Wallpapers', reply: 'Here is a Wallpaper!🌅', replyimg: replyimg },
    { text: '/Gamedeals', reply: 'No Gamedeals!☹️' },
  ];

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setCurrentMessage((prev) => {
        const nextMessage = prev + 1;
        return nextMessage <= messages.length ? nextMessage : 1;
      });
      setShowReply(false);
      setTimeout(() => setShowReply(true), 1500);
    }, 3000);
  
    return () => clearInterval(messageInterval);
  }, [messages.length]);
  
  useEffect(() => {
    if (currentMessage > 0 && showReply) {
      setShowImage(false);
      const imageTimeout = setTimeout(() => setShowImage(true), 500);
      return () => clearTimeout(imageTimeout);
    }
  }, [currentMessage, showReply]);

  const everydayphone = {
    alt: "Nerdcake Running"
  };

  return (
    <section id="nerdcake" className="nerdcake-main">

        <Helmet>
        <title>Nisal Herath - Nerdcake bot </title>
        <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
        <meta name="keywords" content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka" />
        <meta name="author" content="Nisal Herath" />
        <meta property="og:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta property="og:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta property="og:image" content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364" />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta name="twitter:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="nerdcake-component">
      <motion.div
          className="nerdcake"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          variants={fadeVariant}
          transition={{ duration: 0.5 }}
        >
          
          <div className="maintwocards">
          <Link to="/#fun" className="backhome3" style={{ textDecoration: "none", color: "inherit" }}>
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back Home
          </Link>

  <div className="cakecard">
    <motion.div
      className="cakepic"
      initial="hidden"
      animate="visible"
      variants={slideXVariant}
      transition={{ duration: 0.8 }}
    >
      <img src={nerdcakeimg} alt="Nerdcake Bot Logo" />
    </motion.div>

    <motion.h2
      className="cakename"
      initial="hidden"
      animate="visible"
      variants={slideYVariant}
      transition={{ duration: 0.8 }}
    >
      Nerdcake
    </motion.h2>

    <motion.p
      className="cake-desc"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={slideXVariant}
      transition={{ duration: 0.8, delay: 0.4 }}
    >
      Nerdcake is a versatile utility Discord bot designed to simplify tasks
      and enhance user engagement. Packed with features like fetching random
      images, providing game deals, delivering motivational quotes, and even
      running speed tests, Nerdcake is your go-to bot for automation and
      convenience.
    </motion.p>
  </div>

  <div className="commandcard">
    <p className="commandtitle">Commands</p>
    <div className="commandlines">
      <motion.p
        className="commandlinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0 }}
      >
        /ip 🌐💻
      </motion.p>

      <motion.p
        className="commandlinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        /wallpapers 🖼️🖥️
      </motion.p>

      <motion.p
        className="commandlinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        /gamedeals 🎮💸
      </motion.p>

      <motion.p
        className="commandlinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        /advice 💡🗣️
      </motion.p>

      <motion.p
        className="commandlinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0.8 }}
      >
        /positivity ✨🌟
      </motion.p>

      <motion.p
        className="commandlinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 1 }}
      >
        /yomama 🤪💬
      </motion.p>
    </div>
  </div>
</div>


          <div className="graphcard">
            <div className="graphgraph">
              <motion.div
                className="bar1ofgraph"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                variants={barHeightVariant("30%")}
                transition={{ duration: 1 }}
              >
                <img src={codepenLogo} className="codepenbar" alt="codepen" />
                <p>30%</p>
              </motion.div>

              <motion.div
                className="bar2ofgraph"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                variants={barHeightVariant("80%")}
                transition={{ duration: 1 }}
              >
                <img src={githubLogo} className="codepenbar" alt="github" />
                <p>80%</p>
              </motion.div>

              <motion.div
                className="bar3ofgraph"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                variants={barHeightVariant("55%")}
                transition={{ duration: 1 }}
              >
                <img src={discordLogo} className="codepenbar" alt="discord" />
                <p>55%</p>
              </motion.div>

              <motion.div
                className="bar4ofgraph"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                variants={barHeightVariant("70%")}
                transition={{ duration: 1 }}
              >
                <img src={behanceLogo} className="codepenbar" alt="behance" />
                <p>70%</p>
              </motion.div>

              <p className="unness">Unnecessary Graph</p>
              <div className="graphline"></div>
            </div>

            <div className="graph-desc">
              <p className="graphtext">
                Behold, the totally unnecessary bar graph! It serves no purpose whatsoever, 
                except to remind you that even Nerdcake deserves a slice of visual comedy. 
                Because who doesn’t love a graph that does absolutely nothing?
              </p>
              <a
  href="https://discord.com/oauth2/authorize?client_id=1288790078826479646"
  target="_blank"
  rel="noopener noreferrer"
  className="discordbtn"
  aria-label="Try it on Discord"
  style={{ textDecoration: 'none' }} // Remove text decoration
>
  <img src={discord} className="discordimg" alt="Discord Nerdcake" />
  Try it on Discord
</a>

            </div>

      <motion.div className="running24"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={slideXVariant}
      transition={{ duration: 0.8, delay: 0.4 }}
    >
        <CircularProgressbar
          value={percentage}
          styles={buildStyles({
            textColor: "black",
            pathColor: "black",
            trailColor: "#d6d6d6",
            strokeLinecap: "round",
            transition: "stroke-dashoffset 0.6s ease 0s", // Smooth transition
          })}
        />
        <h1 className="runningheader">99.9% UPTIME</h1>
      </motion.div>

<div className="phonecontainer">
      <motion.div
      className="everydayphone2"
      alt="everydayphone"
      data-tooltip-id="everyday-tooltip"
      data-tooltip-content={everydayphone.alt} 
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={slideInFromRight}
      transition={{ duration: 0.8 }}
    >

<ReactTooltip
           id="everyday-tooltip"
           effect="solid"                // Appearance effect
           place="top"                   // Tooltip position
           multiline={true}              // Enable multiline content
           className="custom-tooltip"    // Apply custom CSS class
           clickable={true}              // Tooltip remains open on click
           delayShow={100}               // Delay before tooltip appears
           delayHide={100}               // Delay before tooltip hides
           positionStrategy="fixed"      // Fixed position for consistency
           resizeObserver={true}         // Dynamically adjusts position on resize
           closeOnScroll={true}          // Hides tooltip on scroll  // Hides the tooltip if the user scrolls the page
           arrowColor="rgba(31, 22, 83, 0.7)"
          />
    <div className="phonebtn123"></div>
    <div className="phonespeaker123"></div>
    <div className="phonecamera123"></div>


    <AnimatePresence>
      {/* Send Message Divs */}
      {currentMessage > 0 && (
        <motion.div
          className={`sendermsg123 smsg${currentMessage}`} 
          key={`sendermsg-${currentMessage}`}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }} 
        >
          <p className={`smsg${currentMessage}`}>{messages[currentMessage - 1].text}</p>
        </motion.div>
      )}

      {/* Receive Message Divs */}
      {currentMessage > 0 && showReply && (
        <motion.div
          className={`recievermsg123 rmsg${currentMessage}`}
          key={`receivermsg-${currentMessage}`}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          {/* Render Reply Text */}
          <p className={`rmsg${currentMessage}`}>{messages[currentMessage - 1].reply}</p>

          {/* Conditionally Render Reply Image after delay */}
          {showImage && messages[currentMessage - 1].replyimg && (
            <motion.img
              src={messages[currentMessage - 1].replyimg}
              alt="Reply Visual"
              className={`replyimg123 rimg${currentMessage}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
  </div>



          </div>





        </motion.div>
      </div>
    </section>
  );
};

export default Nerdcake;


