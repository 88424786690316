import React from "react";
import { Link } from "react-router-dom"; // Import Link for SPA navigation
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import "./Fun.css";

const FunItems = () => {
  const items = [
    { href: "/css", label: "CSS Tips", emoji: "💡", external: false },
    { href: "/nerdcake", label: "Nerdcake Bot", emoji: "🍰", external: false },
    {
      href: "https://minigamexyz.netlify.app/",
      label: "Fun Game",
      emoji: "🎮",
      external: true,
    },
    { href: "/advice", label: "Random Advice", emoji: "🧙🏻‍♂️", external: false },
    { href: "/astro", label: "Moon Phase", emoji: "🌒🌕🌘", external: false },
    { href: "/qr-generator", label: "QR Generation", emoji: "⚙️", external: false },
    { href: "/wallpaper-gallery", label: "Doom-Scroll Images", emoji: "🌅", external: false },
  ];

  return (
    <div className="fun-items">
      {items.map(({ href, label, emoji, external }) =>
        external ? (
          // Use <a> for external links
          <a
            key={label}
            className={`item item${label.replace(/\s+/g, "").toLowerCase()}`}
            href={href}
            aria-label={label}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <p className="itemtitle">{emoji}</p>
            <p className="itemtitle">{label}</p>
          </a>
        ) : (
          // Use <Link> for internal links
          <Link
            key={label}
            className={`item item${label.replace(/\s+/g, "").toLowerCase()}`}
            to={href}
            aria-label={label}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <p className="itemtitle">{emoji}</p>
            <p className="itemtitle">{label}</p>
          </Link>
        )
      )}
    </div>
  );
};

const Fun = () => {
  return (
    <section id="fun" className="fun-main">
      <Helmet>
        <meta
          name="description"
          content="Discover exciting features like CSS tips, the Nerdcake bot, a fun game, random advice, and today's moon phase. Explore and enjoy interactive content!"
        />
        <meta
          name="keywords"
          content="CSS Tips, Nerdcake, Fun Game, Random Advice, Moon Phase, Interactive Content, Nisal Herath"
        />
        <meta property="og:title" content="Explore Fun Features - Nisal Herath" />
        <meta
          property="og:description"
          content="Discover exciting features like CSS tips, the Nerdcake bot, a fun game, random advice, and today's moon phase. Explore and enjoy interactive content!"
        />
        <meta property="og:url" content="https://nisal.lk/fun" />
        <meta
          property="og:image"
          content="https://nisal.lk/assets/fun-preview.jpg"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Explore Fun Features - Nisal Herath" />
        <meta
          name="twitter:description"
          content="Interactive features like CSS tips, Nerdcake, fun games, random advice, and more."
        />
        <meta
          name="twitter:image"
          content="https://nisal.lk/assets/fun-preview.jpg"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <motion.div
        className="fun-component"
        initial={{ opacity: 0, scale: 0.9 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="projectsheader2">What I do 4 FUN</h1>
        <FunItems />
      </motion.div>
    </section>
  );
};

export default Fun;
